import { accountService } from '../_services'
import { alertActions } from "../_actions";
export const accountActions = {
    updateAccount
}

function updateAccount(account) {
    return dispatch => {
        accountService.updateAccount(account)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully updated account`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}