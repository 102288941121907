import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap';
import { stripeService, userService } from '../../../_services';
import { accountService } from '../../../_services/accounts.service';
import { accountActions } from '../../../_actions/account.actions';

export default function PricingModal(props) {
    const { showModal, handleClose } = props;
    const [numberOfUsers, setNumberOfUsers] = useState("1");
    const [isYearly, setIsYearly] = useState(false);
    const [isContactForm, setIsContactForm] = useState(false);
    const user = useSelector(state => state.authentication.user);
    const [userEmail, setUserEmail] = useState('');
    const dispatch = useDispatch();

    const planPrices = {
        free: { monthly: 0, yearly: 0 },
        standard: { monthly: 20, yearly: 20 * 12 * 0.9 }
    };

    useEffect(() => {
        if (showModal) {
            setIsContactForm(false);
        }
    }, [showModal]);

    useEffect(() => {
        userService.getById(user.id, { include: ['photo'] })
            .then(user => setUserEmail(user.email))
            .catch(error => {
                console.error("Failed to fetch user data:", error);
            });
    }, [user.id]);


    function setTenantNewAccount() {
        accountService.getAccount([]).then(response => {
            const accountData = response.items[0];
            if (accountData) {
                if (accountData.newAccount) {
                    const accountUpdate = { id: accountData.id, newAccount: false };
                    dispatch(accountActions.updateAccount(accountUpdate));
                    handleClose();
                } else {
                    stripePortal();
                }
            }
        });
    }

    function calculatePrice(planType) {
        if (planType in planPrices) {
            const price = isYearly ? planPrices[planType].yearly : planPrices[planType].monthly;
            const totalPrice = price * numberOfUsers;
            return `$${totalPrice.toFixed(2)}`;
        }
        return '$0';
    }

    function calculateSubtitlePrice(planType) {
        if (planType in planPrices && isYearly) {
            const yearlyEquivalentPerUser = planPrices[planType].yearly;
            const monthlyEquivalentForUsers = yearlyEquivalentPerUser * numberOfUsers;
            return `$${monthlyEquivalentForUsers.toFixed(2)} billed yearly`;
        }
        return `$${(planPrices[planType]?.monthly * numberOfUsers).toFixed(2)} billed monthly`;
    }

    function handleUserChange(event) {
        const value = Math.max(1, parseInt(event.target.value) || 1);
        setNumberOfUsers(value);
    }

    function handleToggleBilling(event) {
        setIsYearly(event.target.checked);
    }

    function stripePortal() {
        stripeService.getPortal()
            .then(s => {
                location.href = s.url;
                target = "_blank";
            });
    }

    function handleContactClick() {
        setIsContactForm(true);
    }

    function handleBackToPricing() {
        setIsContactForm(false);
    }

    function renderPricingContent() {
        return (
            <div className="pricing-card">
                <div className="pricing-card-number-users">
                    <label>How many users do you have?</label>
                    <input
                        type="number"
                        className="input"
                        value={numberOfUsers}
                        onChange={handleUserChange}
                    />
                </div>
                <div className="pricing-card-month-year">
                    {/* <label>Monthly</label>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={isYearly}
                            onChange={handleToggleBilling}
                        />
                        <span className="slider round"></span>
                    </label>
                    <label>Yearly</label> */}
                    <div className={`pricing-card-month ${!isYearly ? "active" : ""}`} onClick={() => setIsYearly(false)}>
                        Monthly
                    </div>
                    <div className={`pricing-card-year ${isYearly ? "active" : ""}`} onClick={() => setIsYearly(true)}>
                        Yearly
                    </div>
                </div>
                <div className='pricing-card-discount'>Save 10% with Annual Billing</div>
                <div className="pricing-card-preview-wrapper">
                    {/* Free Plan */}
                    <div className="pricing-card-item">
                        <div>
                            <div className="pricing-card-item-title">Free</div>
                            <div className="pricing-card-item-subtitle">Get started with no cost for free</div>
                        </div>
                        <div>
                            <div className="pricing-card-item-price">{calculatePrice('free')}</div>
                            <div className="pricing-card-item-subtitle-price">{calculateSubtitlePrice('free')}</div>
                        </div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>No billing</span>
                            <span><i className="fas fa-check-circle"></i>1 User</span>
                            <span><i className="fas fa-check-circle"></i>On Demand Only</span>
                            <span><i className="fas fa-check-circle"></i>120 Minutes</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={setTenantNewAccount}>Continue for Free</div>
                    </div>

                    {/* Standard Plan */}
                    <div className="pricing-card-item popular">
                        <div>
                            <div className="pricing-card-item-title">Standard</div>
                            <div className='popular-tag'>Popular</div>
                            <div className="pricing-card-item-subtitle">Best for small businesses</div>
                        </div>
                        <div>
                            <div className="pricing-card-item-price">{calculatePrice('standard')}</div>
                            <div className="pricing-card-item-subtitle-price">{calculateSubtitlePrice('standard')}</div>
                        </div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>From 2 to 10 Users</span>
                            <span><i className="fas fa-check-circle"></i>50GB storage</span>
                            <span><i className="fas fa-check-circle"></i>On Demand Only</span>
                            <span><i className="fas fa-check-circle"></i>1,000 Minutes</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={stripePortal}>Get Started</div>
                    </div>

                    {/* Enterprise Plan */}
                    <div className="pricing-card-item">
                        <div>
                            <div className="pricing-card-item-title">Enterprise</div>
                            <div className="pricing-card-item-subtitle">Multi-national retail network</div>
                        </div>
                        <div className="pricing-card-item-price-enterprice">For pricing details contact our sales team</div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>More Than 10 Users</span>
                            <span><i className="fas fa-check-circle"></i>Appointments</span>
                            <span><i className="fas fa-check-circle"></i>Products & Orders</span>
                            <span><i className="fas fa-check-circle"></i>Analytics</span>
                            <span><i className="fas fa-check-circle"></i>Custom Minutes</span>
                            <span><i className="fas fa-check-circle"></i>Many More Features</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={handleContactClick}>Contact Us</div>
                    </div>
                </div>
            </div>
        );
    }

    function renderContactForm() {
        return (
            <div className="contact-form">
                <div className='contact-form-title-wrap'>
                    <img className='contact-form-img' src="./Contact_Us_Vector.svg" alt="logo" />
                    <div className='contact-form-title'>Contact Us</div>
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" defaultValue={user.name} placeholder="Enter Name" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" defaultValue={userEmail} placeholder="Enter Email" />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" id="message" rows="3" placeholder="Your message"></textarea>
                </div>
                <div className='contact-form-wrap'>
                    <div className='contact-form-back' onClick={handleBackToPricing}>Back</div>
                    <div className='contact-form-send'>Send</div>
                </div>
            </div>
        );
    }

    return (
        <Modal size='xl' show={showModal} onHide={handleClose} className='pricing-modal'>
            <Modal.Header closeButton>
                <Modal.Title>{isContactForm ? '' : 'Choose the Plan that Fits Your Business'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isContactForm ? renderContactForm() : renderPricingContent()}
            </Modal.Body>
        </Modal>
    );

}