import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { generateOptions } from './options';

export const apiService = {
    getItem,
    getItems,
    addItem,
    updateItem,
    deleteItem,
}

export type ApiOptions = {
    handled?: boolean;
    sortDirection?: string;
    sortBy?: string;
    include?: string[];
    pageSize?: number;
    page?: number;
    searchValues?: any[];
    searchFields?: string[];
    searchValue?: any;
    searchField?: string;    
}

function headers(): HeadersInit {
    return {
        ...authHeader(),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}

function getItem(path: string, id: number, options?: ApiOptions) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers()
    };
    let url = `${config.apiUrl}/${path}`;
    if (id)
        url += `/${id}`;

    if (options) {
        url += generateOptions(url, options);
    }
    if (options && options.handled)
        return fetch(url, requestOptions)
    else
        return fetch(url, requestOptions)
            .then(r => handleResponse(r))
}

function getItems(path: string, options?: ApiOptions) {
    const requestOptions = {
        method: 'GET',
        headers: headers()
    };
    let url = `${config.apiUrl}/${path}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function addItem(path: string, object: any, handled = false) {
    const requestOptions = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(object)
    };
    let url = `${config.apiUrl}/${path}`;
    return fetch(url, requestOptions)
        .then((res) => handleResponse(res, handled))
}

function updateItem(path: string, object:any, handled = false) {
    const requestOptions = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(object)
    };
    let url = `${config.apiUrl}/${path}`;
    return fetch(url, requestOptions)
    .then((res) => handleResponse(res, handled))
}

function deleteItem(path: string, id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: headers(),
    };
    let url = `${config.apiUrl}/${path}/${id}`;
    return fetch(url, requestOptions)
        .then(handleResponse)
} 
